<template>
  <div class="home">
    <!-- <Header></Header> -->
    <!-- <div class="about" @click="goAbout">关于</div> -->
    <!-- <svg-icon icon-class="refresh" /> -->
    <!-- <div>首页</div>
    <div class="about" @click="goAbout">关于</div>
    <ul class="img">
      <li v-for="(item, index) in imgList">
        <img v-lazy="item" alt="" style="width: 768px" />
      </li>
    </ul> -->
    <Header></Header>

    <full-page :options="options" :class="isFirstPage ? 'home_main' : ''">
      <div class="section fp-auto-height home_banner">
        <div class="swiper-container">
          <div class="swiper-wrapper">
            <!-- <div class="swiper-slide">
              <div class="home_slide_banner img_content_pub">
                <video width="100%" height="100%" data-autoplay controls>
                  <source :src="videoUrl" type="video/mp4" />
                </video>
              </div>
            </div> -->
            <div class="swiper-slide" v-for="(item, index) in imgList" :key="item.id">
              <div
                v-if="item.type == 2"
                class="home_slide_banner img_content_pub video_content"
                @mousemove="debounceAjax"
              >
                <video
                  id="myAudio"
                  width="100%"
                  height="auto"
                  :muted="muted"
                  :autoplay="true"
                  :poster="
                    item.resourcesUrl + '?x-oss-process=video/snapshot,t_1000,m_fast'
                  "
                  :enable-progress-gesture="false"
                  :controls="false"
                >
                  <!-- <source src="../assets/imgs/henan_home.mp4" type="video/mp4" /> -->
                  <source :src="item.resourcesUrl" type="video/mp4" />
                </video>
                <div v-if="isMove && !isPlay" class="paly_btn" @click="play">
                  <img src="../assets/imgs/play.png" alt="" />
                </div>
                <div v-if="isMove && isPlay" class="paly_btn" @click="stop">
                  <img src="../assets/imgs/stop.png" alt="" />
                </div>
                <div v-if="isMove && !muted" class="voice_btn" @click="voice">
                  <img src="../assets/imgs/voice.png" alt="" />
                </div>
                <div v-if="isMove && muted" class="voice_btn" @click="mute">
                  <img src="../assets/imgs/mute.png" alt="" />
                </div>
                <!-- <div class="audi_btn">静音</div> -->
              </div>
              <div v-else class="home_slide_banner img_content_pub">
                <img :src="item.resourcesUrl" alt="" />
              </div>
            </div>
          </div>
          <div class="swiper-pagination"></div>
        </div>
      </div>
      <div class="section fp-auto-height product">
        <div class="title">
          <img src="../assets/imgs/title_bg_left.png" alt="" />
          <span>产品中心</span>
          <img src="../assets/imgs/title_bg_right.png" alt="" />
        </div>
        <div class="product-swiper">
          <div class="swiper-wrapper">
            <div class="swiper-slide" v-for="(item, index) in productList" :key="index">
              <div class="product_item_content" @click="goHometownGift">
                <div
                  class="product_item_main"
                  @mouseenter="onMouseenter(index)"
                  @mouseleave="onMouseleave()"
                  :class="enterActive == index ? 'mouseenter' : 'mouseleave'"
                >
                  <div class="product_item">
                    <div class="item_img img_content_pub">
                      <img :data-src="item.proPicture" alt="" />
                    </div>
                    <div class="product_name">{{ item.proName }}</div>
                    <div class="line"></div>
                    <div class="detail">
                      {{ item.proBriefIntroduction }}
                    </div>
                    <div class="more">
                      <span>MORE</span><svg-icon icon-class="arrow" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div class="swiper-slide">Slide 2</div>
            <div class="swiper-slide">Slide 3</div> -->
          </div>
          <!-- 如果需要分页器 -->
          <!-- <div class="swiper-pagination"></div> -->

          <!-- 如果需要导航按钮 -->
          <div class="swiper-button-prev"></div>
          <div class="swiper-button-next"></div>

          <!-- 如果需要滚动条 -->
          <!-- <div class="swiper-scrollbar"></div> -->
        </div>
      </div>
      <div class="section fp-auto-height about">
        <div class="title">
          <img src="../assets/imgs/title_bg_left.png" alt="" />
          <span>关于老家河南</span>
          <img src="../assets/imgs/title_bg_right.png" alt="" />
        </div>
        <div class="about_content">
          <div class="about_text">
            <div class="about_title">企业简介</div>
            <p>
              老家河南酒业集团有限公司，由河南省文旅投资集团旗下的河南省中视新科文化产业有限公司联合河南日报报业集团旗下大河传媒有限公司共同合资成立，民营企业参股，以市场为导向，做国资控股、民营主导的市场型合资企业。作为河南省唯一国资控股的综合性酒业集团，老家河南酒业集团肩负弘扬中原文化使命，全力推动豫酒振兴，依托不断出圈的河南文化资源与文旅产业发展大势，以厚重文化与国潮风格为脉络，不断打造更符合河南消费者和市场的优质好酒。
            </p>
            <p>
              老家河南酒业集团坚决怀抱开放的心态，坚持做文创酒平台型企业，为消费者喝的每一瓶酒赋予文化内涵，做一亿河南人的待客酒！
            </p>
          </div>
          <div class="img_content_pub about_img">
            <img :data-src="require('../assets/imgs/about.png')" alt="" />
          </div>
        </div>
      </div>
      <div class="section fp-auto-height development">
        <div class="dev_title">老家河南三个战略阶段</div>
        <div class="dev_list">
          <div class="dev_list_content">
            <div
              class="list_item"
              :class="devActive == 0 ? 'active' : ''"
              @click="onChangeDev(0)"
            >
              <div class="item_top">
                <img src="../assets/imgs/one.png" alt="" />
              </div>
              <div class="item_bottom">
                <div class="item_title">文化战略阶段</div>
                <div class="time">2009-2012</div>
              </div>
            </div>
            <div
              class="list_item line"
              :class="devActive == 1 ? 'active' : ''"
              @click="onChangeDev(1)"
            >
              <div class="item_top">
                <img src="../assets/imgs/tow.png" alt="" />
              </div>
              <div class="item_bottom">
                <div class="item_title">品牌战略阶段</div>
                <div class="time">2012-2019</div>
              </div>
            </div>
            <div
              class="list_item"
              :class="devActive == 2 ? 'active' : ''"
              @click="onChangeDev(2)"
            >
              <div class="item_top">
                <img src="../assets/imgs/three.png" alt="" />
              </div>
              <div class="item_bottom">
                <div class="item_title">生态战略阶段</div>
                <div class="time">2019-至今</div>
              </div>
            </div>
          </div>
          <div class="time_line">
            <div class="line_item">
              <img
                class="active"
                v-if="devActive == 0"
                src="../assets/imgs/time_dot_active.png"
                alt=""
              />
              <!-- <img class="normal" v-else src="../assets/imgs/time_dot.png" alt="" /> -->
              <span v-else class="normal"></span>
            </div>
            <div class="line_item">
              <img
                class="active"
                v-if="devActive == 1"
                src="../assets/imgs/time_dot_active.png"
                alt=""
              />
              <span v-else class="normal"></span>
            </div>
            <div class="line_item">
              <img
                class="active"
                v-if="devActive == 2"
                src="../assets/imgs/time_dot_active.png"
                alt=""
              />
              <span v-else class="normal"></span>
            </div>
          </div>
        </div>
        <div v-for="(swiperItem, swiperIndex) in developmentList" :key="swiperIndex">
          <div
            class="development-swiper"
            :class="'development-swiper-' + swiperIndex"
            v-if="devActive == swiperIndex"
          >
            <div class="swiper-wrapper">
              <div class="swiper-slide" v-for="(item, index) in swiperItem" :key="index">
                <div class="development_item">
                  <div class="dev_slide_img img_content_pub">
                    <img :src="item.img" alt="" />
                  </div>
                  <div class="detail">
                    <div class="sub_title">{{ item.year }}{{ item.title }}</div>
                    <div class="line"></div>
                    <p class="content_text">
                      {{ item.desc }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <!-- 如果需要分页器 -->
            <div class="swiper-pagination"></div>

            <!-- 如果需要导航按钮 -->
            <div
              v-if="devActive == 0 || (devActive != 0 && !reachStart)"
              class="swiper-button-prev"
            ></div>
            <div v-else class="swiper_button_prev" @click="changeDevActive1"></div>
            <div v-if="!reachEnd" class="swiper-button-next"></div>
            <div v-else class="swiper_button_next" @click="changeDevActive"></div>
          </div>
        </div>
      </div>
      <div class="section fp-auto-height media_center">
        <div class="title" style="margin-bottom: 20px">
          <img src="../assets/imgs/media_left.png" alt="" />
          <span>媒体中心</span>
          <img src="../assets/imgs/media_right.png" alt="" />
        </div>
        <div class="btn_group">
          <div
            class="btn"
            :class="mediaActive == 0 ? 'active' : ''"
            @click="onMediaChange(0)"
          >
            企业动态
          </div>
          <div
            class="btn"
            :class="mediaActive == 1 ? 'active' : ''"
            @click="onMediaChange(1)"
          >
            新闻报道
          </div>
        </div>
        <ul class="new_list">
          <li
            class="list_item"
            v-for="(item, index) in newsList"
            @click="goDetail(item.id)"
          >
            <div
              class="img_content_pub item_img"
              @mouseenter="onNewsMouseenter(index)"
              @mouseleave="onNewsMouseleave(index)"
              :class="newsActive == index ? 'img_enlarge' : ''"
            >
              <img
                :class="newsLeave == index ? 'img_smaller' : ''"
                :src="item.picUrl"
                alt=""
              />
            </div>

            <div class="content">
              <div class="news_title">
                {{ item.title }}
              </div>
              <p class="text">
                {{ item.abstracts }}
              </p>
            </div>
            <img class="media_arrow" src="../assets/imgs/media_arrow.png" alt="" />
          </li>
          <!-- <li class="list_item">
            <div class="img_content_pub item_img">
              <img src="../assets/imgs/test.png" alt="" />
            </div>
            <div class="content">
              <div class="title">
                老家河南王孟：借超级IP文创优势，更多赋能“1亿人的待客酒”丨中酒展专访
              </div>
              <p class="text">
                4月26日下午，以“华夏之源，豫鉴中国”为主题的“老家河南”酒业战略合作暨豫鉴系列新品发布盛典在郑州举行，现场首次以著名IP“老家河南”作为独立产品品牌亮相的...
              </p>
            </div>
            <img class="media_arrow" src="../assets/imgs/media_arrow.png" alt="" />
          </li> -->
          <!-- <li class="list_item">
            <div class="img_content_pub item_img">
              <img src="../assets/imgs/test.png" alt="" />
            </div>
            <div class="content">
              <div class="title">
                老家河南王孟：借超级IP文创优势，更多赋能“1亿人的待客酒”丨中酒展专访
              </div>
              <p class="text">
                4月26日下午，以“华夏之源，豫鉴中国”为主题的“老家河南”酒业战略合作暨豫鉴系列新品发布盛典在郑州举行，现场首次以著名IP“老家河南”作为独立产品品牌亮相的...
              </p>
            </div>
            <img class="media_arrow" src="../assets/imgs/media_arrow.png" alt="" />
          </li> -->
        </ul>
        <router-link class="to_media_center" to="/mediaCenter">更多 ></router-link>
      </div>
      <div class="section fp-auto-height footer">
        <Footer></Footer>
      </div>
    </full-page>
  </div>
</template>

<script>
//页面引入swiper
// import { swiper, swiperSlide } from "vue-awesome-swiper";
// import "swiper/swiper-bundle.css";
import Swiper from "swiper";
import "swiper/dist/css/swiper.min.css";
import "fullpage.js/dist/fullpage.min.css";

import Footer from "../components/footer";
import Header from "../components/header";
import { getBannerList, getProductList, getNewsList } from "../api/api";
import { historyList } from "../utils/text";

export default {
  name: "Home",
  components: {
    Footer,
    Header,
  },
  data() {
    return {
      imgList: [
        // require("../assets/imgs/banner.png"),
        // "https://gxj-supply-chain.oss-cn-shenzhen.aliyuncs.com/img/202202081722290190927.jpg",
        // "https://gxj-supply-chain.oss-cn-shenzhen.aliyuncs.com/img/202202081722200190708.jpg",
      ],
      videoUrl: "",
      productList: [
        // require("../assets/imgs/wine_1.png"),
        // require("../assets/imgs/wine_2.png"),
        // require("../assets/imgs/wine_3.png"),
        // require("../assets/imgs/wine_2.png"),
      ],
      newsList: [],
      developmentList: historyList,
      options: {
        licenseKey: "OPEN-SOURCE-GPLV3-LICENSE",
        scrollOverflow: false,
        scrollBar: false,
        onLeave: this.onLeave,
        afterLoad: this.afterLoad,
        // anchors: ['winePage1', 'winePage2', 'winePage3'],
        // sectionsColor: ["#41b883", "#ff5f45", "#0798ec"],
        navigation: true,
        navigationPosition: "right",
        // paddingTop: "120px",
        // fitToSection: false
        // fixedElements: '#menu'
      },
      devActive: 0,
      mediaActive: 0,
      swiper: null,
      mySwiper: null,
      enterActive: -1,
      leaveActive: -1,
      newsList: [],
      newsActive: -1,
      newsLeave: -1,
      autoplay: 0,
      isPlay: true,
      aud: null,
      muted: true,
      debounceAjax: null,
      isMove: false,
      swiper: null,
      reachEnd: false,
      reachStart: false,
      goPre: false,
      // videoAutoplay: false
      isFirstPage: true,
    };
  },
  mounted() {
    this.getBannerList();
    this.getProductList();
    this.getNewsList(1);
    this.$nextTick(() => {
      //禁用向下滚动
      fullpage_api.setAllowScrolling(false, "down");
      //禁用向上滚动
      fullpage_api.setAllowScrolling(false, "up");
      //注册事件
      window.onmousewheel = document.onmousewheel = this.scrollFunc;
    });

    new Swiper(".product-swiper", {
      loop: false,
      // 如果需要分页器
      // pagination: ".swiper-pagination",
      slidesPerView: 3,
      // 如果需要前进后退按钮
      nextButton: ".swiper-button-next",
      prevButton: ".swiper-button-prev",
      // 如果需要滚动条
      // scrollbar: ".swiper-scrollbar",
      // observer 修改swiper子元素时自动初始化swiper
      observer: true,
      // observeParents 包括当前父元素的swiper发生变更时也会初始化swiper
      observeParents: true,
    });
    // this.swiper = new Swiper(".development-swiper", {
    //   loop: false,
    //   // 如果需要分页器
    //   pagination: ".swiper-pagination",
    //   slidesPerView: 1,
    //   // 如果需要前进后退按钮
    //   nextButton: ".swiper-button-next",
    //   prevButton: ".swiper-button-prev",
    //   // observer 修改swiper子元素时自动初始化swiper
    //   observer: true,
    //   // observeParents 包括当前父元素的swiper发生变更时也会初始化swiper
    //   observeParents: true,
    //   // 如果需要滚动条
    //   // scrollbar: ".swiper-scrollbar",
    // });
    this.initSwiperDev(0);
    this.mySwiper = new Swiper(".swiper-container", {
      // loop: true,
      effect: "fade",
      // 如果需要分页器
      pagination: ".swiper-pagination",
      slidesPerView: 1,
      // 如果需要前进后退按钮
      // nextButton: ".swiper-button-next",
      // prevButton: ".swiper-button-prev",
      // 如果需要滚动条
      // scrollbar: ".swiper-scrollbar",
      // observer 修改swiper子元素时自动初始化swiper
      observer: true,
      // observeParents 包括当前父元素的swiper发生变更时也会初始化swiper
      observeParents: true,
      paginationClickable: true,
      autoplayDisableOnInteraction: false,
      autoplay: 5000,
      // loopAdditionalSlides: 2
      // speed: 2000
    });
    this.mySwiper.stopAutoplay();

    // this.$nextTick(function () {

    // });
    this.debounceAjax = this.debounce(this.onMousemove, 1000, true);
  },
  beforeRouteLeave(to, from, next) {
    // if (this.username && this.password) {
    //   next();
    // } else {
    //   if (confirm("表单还没填完,你真的要离开吗?")) {
    //     next();
    //   } else {
    //     next(false);
    //   }
    // }
    window.onmousewheel = document.onmousewheel = null;
    next();
  },

  methods: {
    changeDevActive() {
      console.log("changeDevActive");
      // this.swiper.init();
      this.reachEnd = false;
      if (this.devActive < 2) {
        this.devActive++;
      }
      this.goPre = false;
      this.$nextTick(() => {
        this.initSwiperDev(this.devActive);
      });
    },
    changeDevActive1() {
      // this.swiper.init();
      console.log("changeDevActive1");
      if (this.devActive > 0) {
        this.devActive--;
      }
      this.goPre = true;
      this.$nextTick(() => {
        this.initSwiperDev(this.devActive);
      });
      this.reachStart = false;
    },
    initSwiperDev(index) {
      var self = this;
      this.swiper = new Swiper(".development-swiper-" + index, {
        loop: false,
        // 如果需要分页器
        pagination: ".swiper-pagination",
        slidesPerView: 1,
        // 如果需要前进后退按钮
        nextButton: ".swiper-button-next",
        prevButton: ".swiper-button-prev",
        // 如果需要滚动条
        // scrollbar: ".swiper-scrollbar",
        // observer 修改swiper子元素时自动初始化swiper
        observer: true,
        // observeParents 包括当前父元素的swiper发生变更时也会初始化swiper
        observeParents: true,
        onReachEnd: function () {
          // alert("到了最后一个slide");
          if (self.devActive != 2) {
            self.reachEnd = true;
            console.log(self.reachEnd);
          }
        },
        onReachBeginning: function () {
          self.reachStart = true;
          self.reachEnd = false;
          console.log("reachStart");
        },
        onSlideChangeStart: function (swiper) {
          // console.log(swiper.activeIndex);
          if (swiper.activeIndex == 1) {
            self.reachStart = false;
          }
        },
      });
      if (this.goPre) {
        this.swiper.slideTo(this.developmentList[this.devActive].length - 1, 0, false);
        this.reachStart = false;
      }
    },
    onMousemove() {
      console.log("onMousemove");

      this.isMove = true;
      setTimeout(() => {
        this.isMove = false;
      }, 3000);
    },
    debounce(func, wait, immediate) {
      let timeout;
      return function () {
        const context = this;
        const args = arguments;
        if (timeout) {
          clearTimeout(timeout);
        }
        if (immediate) {
          const callNow = !timeout;
          timeout = setTimeout(function () {
            timeout = null;
          }, wait);
          if (callNow) {
            func.apply(context, args);
          }
        } else {
          timeout = setTimeout(function () {
            func.apply(context, args);
          }, wait);
        }
      };
    },
    goHometownGift() {
      // this.$router.push("/hometownGift");
      const { href } = this.$router.resolve({
        name: "HometownGift",
        path: "/hometownGift",
      });
      console.log(href);
      window.open(href, "_blank");
    },
    goDetail(id) {
      // this.$router.push({
      //   path: "/newsDetail",
      //   query: { id: id, active: this.mediaActive },
      // });
      const { href } = this.$router.resolve({
        name: "NewsDetail",
        path: "/newsDetail",
        query: { id: id, active: this.mediaActive },
      });
      console.log(href);
      window.open(href, "_blank");
    },
    onNewsMouseenter(index) {
      this.newsActive = index;
      // this.newsLeave = -1;
      if (this.newsLeave == index) {
        this.newsLeave = -1;
      }
    },
    onNewsMouseleave(index) {
      this.newsLeave = index;
      if (this.newsActive == index) {
        this.newsActive = -1;
      }
      // this.newsActive = -1;
    },
    onMouseenter(index) {
      this.enterActive = index;
    },
    onMouseleave() {
      this.enterActive = -1;
    },
    onMediaChange(index) {
      this.mediaActive = index;
      this.newsLeave = -1;
      this.newsActive = -1;
      this.newsList = [];
      this.getNewsList(index + 1);
    },
    play() {
      this.isPlay = true;
      this.aud.play();
      this.mySwiper.stopAutoplay();
    },
    stop() {
      this.isPlay = false;
      this.aud.pause();
      this.mySwiper.startAutoplay();
    },
    voice() {
      this.muted = true;
      // this.aud.play();
      // this.mySwiper.stopAutoplay();
    },
    mute() {
      this.muted = false;
      // this.aud.pause();
      // this.mySwiper.startAutoplay();
    },
    getNewsList(type) {
      var data = {
        pageSize: 3,
        pageNum: 1,
        type: type,
      };
      getNewsList(data).then((res) => {
        console.log(res);
        var self = this;
        if (res.code == 200) {
          if (res.data) {
            this.newsList = res.data.records;
            this.aud = document.getElementById("myAudio");
            this.aud.onended = function () {
              // alert("音频播放完成");
              // self.videoAutoplay = false;
              self.mySwiper.startAutoplay();
              self.isPlay = false;
              self.isMove = true;
            };
            this.aud.onpause = function () {
              // alert("视频暂停播放");
              // self.autoplay = 5000;
              // console.log(self.autoplay);
              // self.videoAutoplay = false;
              self.mySwiper.startAutoplay();
              self.isPlay = false;
              self.isMove = true;
            };
            this.aud.onplay = function () {
              self.mySwiper.stopAutoplay();
            };
          }
        }
      });
    },
    getProductList() {
      getProductList().then((res) => {
        console.log(res);
        if (res.code == 200) {
          this.productList = res.data;
        }
      });
    },
    getBannerList() {
      getBannerList().then((res) => {
        console.log(res);
        if (res.code == 200) {
          this.imgList = res.data;
          // for (var i = 0; i < imgList.length; i++) {
          //   // var len = imgList[i].resourcesUrl.length;
          //   if (imgList[i].type == 2) {
          //     this.videoUrl = imgList[i].resourcesUrl;
          //   } else {
          //     this.imgList.push(imgList[i]);
          //   }
          // }
          // this.imgList.shift();
          console.log(this.imgList);
          this.swiper.init();
        }
      });
    },

    onChangeDev(index) {
      console.log(index);
      this.devActive = index;
      // this.swiper.init();
      this.$nextTick(() => {
        this.initSwiperDev(this.devActive);
      });
    },
    goAbout() {
      // this.$router.push("/about");
    },
    onLeave(origin, destination, direction) {
      //离开上一屏之前
      // console.log(direction)
      // console.log(destination);
      this.isFirstPage = destination.isFirst;
      if (direction == "up") {
        this.isFirstPage = true;
      }
    },
    afterLoad(origin, destination, direction) {
      //进入下一屏之后
    },
    scrollFunc(e) {
      //防抖
      e = e || window.event;
      var delta = null;
      var timer = null;
      if (e.wheelDelta) {
        //IE/Opera/Chrome
        delta = e.wheelDelta;
      } else if (e.detail) {
        //Firefox
        delta = e.detail;
      }
      if (delta < 0) {
        //向下滚
        fullpage_api.moveSectionDown();
      } else if (delta > 0) {
        //向上滚
        fullpage_api.moveSectionUp();
      }
      //为了防止一次滚动太凶而直接跳转到底部，先关闭监听一段时间后再开启
      window.onmousewheel = document.onmousewheel = null;
      clearTimeout(timer);
      timer = setTimeout(() => {
        //再次重新注册
        window.onmousewheel = document.onmousewheel = this.scrollFunc;
      }, 1000);
    },
    scrollFunc(e) {
      //节流
      e = e || window.event;
      var delta = null;
      var timer = null;
      var flag = true; //节流阀
      if (e.wheelDelta) {
        //IE/Opera/Chrome
        delta = e.wheelDelta;
      } else if (e.detail) {
        //Firefox
        delta = e.detail;
      }
      if (!flag) return;
      flag = false;
      if (delta < 0) {
        //向下滚
        fullpage_api.moveSectionDown();
      } else if (delta > 0) {
        //向上滚
        fullpage_api.moveSectionUp();
      }
      //为了防止一次滚动太凶而直接跳转到底部，先关闭监听一段时间后再开启
      window.onmousewheel = document.onmousewheel = null;
      clearTimeout(timer);
      timer = setTimeout(() => {
        //再次重新注册
        flag = true;
        window.onmousewheel = document.onmousewheel = this.scrollFunc;
      }, 1000);
    },
  },
};
</script>
<style lang="scss">
.fullpage-wrapper {
  height: calc(100vh - 120px) !important;
}
.home_main.fullpage-wrapper {
  top: 120px !important;
}
.home_main {
  top: 120px !important;
}
.fp-auto-height.fp-section {
  height: calc(100vh - 120px) !important;
  // position: relative !important;
  // top: 120px !important;
}
.swiper-button-next.swiper-button-disabled,
.swiper-button-prev.swiper-button-disabled {
  pointer-events: auto;
}
</style>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
// .fp-auto-height .fp-slide,
// .fp-auto-height .fp-tableCell,
// .fp-auto-height.fp-section {
//   height: calc(100vh - 120px) !important;
// }
.title {
  margin-bottom: 60px;
  height: 54px;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 70px;
    height: 26px;
  }
  span {
    font-size: 36px;
    color: #d6ac7d;
    margin: 0 10px;
  }
}
// .section{
//   padding-top: 60px;
// }
//
// .home {
//   margin-top: 120px;
// }
.home_banner {
  // top: 120px !important;
  .swiper-container {
    .home_slide_banner {
      width: 100%;
      height: calc(100vh - 120px);
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      user-select: all;
    }
    .video_content {
      position: relative;
      .paly_btn {
        position: absolute;
        top: 50%;
        left: 50%;
        text-align: center;
        width: 100px;
        height: 100px;
        margin-top: -50px;
        margin-left: -50px;
        cursor: pointer;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .voice_btn {
        position: absolute;
        top: 10%;
        right: 10%;
        width: 50px;
        height: 50px;
        cursor: pointer;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  /deep/ .swiper-pagination-bullet {
    background: #f6f6f6;
    opacity: 1;
  }
  /deep/ .swiper-pagination-bullet-active {
    background: #ffc98c;
  }
}
//
.product {
  .product-swiper {
    width: 1200px;
    margin: auto;
    overflow: hidden;
    position: relative;
    .swiper-wrapper {
      width: 1200px !important;
      height: 510px;
      margin: auto;
      .swiper-slide {
        width: 400px !important;
      }
    }
    /deep/ .swiper-button-prev,
    .swiper-container-rtl .swiper-button-next {
      background-image: url("../assets/imgs/arrow_left.png");
      background-size: 20px 36px;
    }
    /deep/ .swiper-button-next,
    .swiper-container-rtl .swiper-button-prev {
      background-image: url("../assets/imgs/arrow_right.png");
      background-size: 20px 36px;
    }
    /deep/ .swiper-button-next,
    .swiper-button-prev {
      width: 20px;
      height: 36px;
    }
  }
  /deep/ .fp-tableCell {
    background: url("../assets/imgs/product_bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    -webkit-background-size: cover;
    -o-background-size: cover;
    background-position: center;
  }

  // height: 100% !important;
  .product_item_content {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 510px;
    cursor: pointer;
  }
  .product_item_main {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 700px;
  }
  .product_item {
    width: 400px;
    height: 510px;
    // position: relative;
    // display: flex;
    // justify-content: center;
    // flex-direction: column;
    .item_img {
      width: 100%;
      height: 260px;
    }
    .product_name {
      color: #d6ac7d;
      font-size: 26px;
      margin-top: 67px;
    }
    .line {
      // margin: auto;
      width: 30px;
      height: 1px;
      background-color: #e2e2e2;
      margin: 16px auto;
    }
    .detail {
      color: #666666;
      font-size: 14px;
      margin-bottom: 28px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding: 0 20px;
    }
    .more {
      color: #888888;
      font-size: 14px;
      cursor: pointer;
      text-decoration: none;
      display: flex;
      justify-content: center;
      align-items: center;
      .svg-icon {
        width: 20px;
        height: 20px;
        vertical-align: middle;
      }
    }
  }
  .product_item_main.mouseenter {
    animation: lengthen 0.5s;
    -webkit-animation: lengthen 0.5s; /* Safari and Chrome */
    animation-fill-mode: forwards;
  }
  .product_item_main.mouseleave {
    animation: shorten 0.5s;
    -webkit-animation: shorten 0.5s;
    animation-fill-mode: forwards;
  }
  @keyframes lengthen {
    from {
      top: 0;
    }
    to {
      top: 40px;
    }
  }

  @-webkit-keyframes lengthen /* Safari 与 Chrome */ {
    from {
      top: 0;
    }
    to {
      top: 40px;
    }
  }
  @keyframes shorten {
    from {
      top: 40px;
    }
    to {
      top: 0;
    }
  }

  @-webkit-keyframes shorten /* Safari 与 Chrome */ {
    from {
      top: 40px;
    }
    to {
      top: 0;
    }
  }
  /deep/ .swiper-slide-next {
    .product_item {
      border-left: 1px solid;
      border-right: 1px solid;
      border-image: -webkit-linear-gradient(
          rgba(255, 255, 255, 0),
          #dbdbdb,
          rgba(255, 255, 255, 0)
        )
        30 30;
      border-image: -moz-linear-gradient(
          rgba(255, 255, 255, 0),
          #dbdbdb,
          rgba(255, 255, 255, 0)
        )
        30 30;
      border-image: linear-gradient(
          rgba(255, 255, 255, 0),
          #dbdbdb,
          rgba(255, 255, 255, 0)
        )
        30 30;
    }
  }
}
//
.about {
  /deep/ .fp-tableCell {
    background: url("../assets/imgs/about_bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    -webkit-background-size: cover;
    -o-background-size: cover;
    background-position: center;
  }
  .about_content {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .about_text {
    width: 500px;
    .about_title {
      text-align: left;
      font-size: 32px;
      color: #d6ac7d;
      margin-bottom: 40px;
    }
    p {
      font-size: 18px;
      color: #666666;
      // text-justify: ;
      text-align: justify;
      line-height: 34px;
    }
  }
  .about_img {
    width: 460px;
    margin-left: 50px;
    img {
      width: 100%;
      height: auto;
    }
  }
}
//
.development {
  background: linear-gradient(90deg, #ca9860 0%, #dcb881 100%);
  .dev_title {
    font-size: 32px;
    color: #fff;
    margin-bottom: 20px;
  }
  .dev_list {
    width: 100%;
    height: 140px;
    background: url("../assets/imgs/dev_bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    -webkit-background-size: cover;
    -o-background-size: cover;
    background-position: center;
    .dev_list_content {
      width: 1200px;
      margin: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      .list_item {
        width: 400px;
        padding: 0 24px;
        cursor: pointer;
      }
      .list_item.line {
        border-left: 1px solid rgba(255, 255, 255, 0.2);
        border-right: 1px solid rgba(255, 255, 255, 0.2);
      }
      .list_item.active {
        background: rgba(255, 230, 182, 0.3);
      }
      .item_top,
      .item_bottom {
        height: 70px;
        // width: ;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        // padding: 0 24px;
      }
      .item_top {
        border-bottom: 1px solid rgba(255, 255, 255, 0.2);
        font-size: 50px;
        color: #ffffff;
        img {
          width: 65px;
          height: auto;
        }
        // font-family: Source Han Serif CN-Bold, Source Han Serif CN;
      }
      .item_bottom {
        color: #fff;
        .item_title {
          font-size: 18px;
        }
        .time {
          font-size: 14px;
        }
      }
    }
  }
  .time_line {
    display: flex;
    justify-content: center;
    align-items: center;
    .line_item {
      height: 20px;
      line-height: 20px;
      width: 400px;
      text-align: center;
      position: relative;
      top: -10px;
      .normal {
        display: inline-block;
        width: 12px;
        height: 12px;
        border-radius: 100%;
        background: #ffa23a;
        vertical-align: middle;
      }
      .active {
        width: 20px;
        height: 20px;
      }
      img {
        vertical-align: middle;
      }
    }
  }
  .development-swiper {
    width: 1200px;
    margin: auto;
    margin-top: 40px;
    overflow: hidden;
    position: relative;
    /deep/ .swiper-button-prev,
    .swiper-container-rtl .swiper-button-next {
      background-image: url("../assets/imgs/dev_arrow_left.png");
      background-size: 46px 46px;
      right: 80px;
      left: auto;
    }
    /deep/ .swiper-button-next,
    .swiper-container-rtl .swiper-button-prev {
      background-image: url("../assets/imgs/dev_arrow_right.png");
      background-size: 46px 46px;
    }
    /deep/ .swiper-button-next,
    .swiper-button-prev {
      width: 46px;
      height: 46px;
      top: auto;
      bottom: 0;
    }
    .swiper_button_prev,
    .swiper_button_next {
      width: 46px;
      height: 46px;
      top: auto;
      bottom: 0;
      background-image: url("../assets/imgs/dev_arrow_left.png");
      background-size: 46px 46px;
      right: 80px;
      left: auto;
      position: absolute;
      margin-top: -22px;
      z-index: 10;
      cursor: pointer;
      background-position: center;
      background-repeat: no-repeat;
    }
    .swiper_button_next {
      background-image: url("../assets/imgs/dev_arrow_right.png");
      right: 10px;
      left: auto;
    }

    .swiper-wrapper {
      width: 1200px !important;
      margin: auto;
      .development_item {
        height: 390px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        .dev_slide_img {
          width: 480px;
          height: 390px;
        }
        img {
          width: 480px;
          height: 390px;
          object-fit: cover;
        }
        .detail {
          margin-left: 50px;
          width: 670px;
          .sub_title {
            text-align: left;
            font-size: 24px;
            color: #fff;
            padding-bottom: 20px;
            border-bottom: 1px solid rgba(255, 255, 255, 0.5);
            margin-bottom: 20px;
          }
          .line {
            width: 160px;
            height: 3px;
            background: #ffffff;
            position: relative;
            top: -21.5px;
          }
          .content_text {
            font-size: 14px;
            color: #fff;
            line-height: 1.8;
            text-align: justify;
          }
        }
      }
    }
  }
  /deep/ .swiper-container-horizontal > .swiper-pagination-bullets,
  .swiper-pagination-custom,
  .swiper-pagination-fraction {
    bottom: 0;
  }
  /deep/ .swiper-pagination-bullet {
    background: #d9b28b;
    opacity: 1;
  }
  /deep/ .swiper-pagination-bullet-active {
    background: none;
    background-image: url("../assets/imgs/dev_dot.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
}
//
.media_center {
  .title {
    span {
      color: #333333;
    }
  }
  /deep/ .fp-tableCell {
    background: url("../assets/imgs/media_bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    -webkit-background-size: cover;
    -o-background-size: cover;
    background-position: center;
  }
  .btn_group {
    margin-bottom: 20px;
    .btn {
      display: inline-block;
      width: 210px;
      height: 54px;
      text-align: center;
      line-height: 54px;
      background: #fdfdfb;
      font-size: 20px;
      color: #333333;
      cursor: pointer;
    }
    .btn.active {
      color: #fff;
      background: #d6ac7d;
    }
    .btn:hover {
      color: #fff;
      background: #d6ac7d;
    }
  }
  .new_list {
    width: 1200px;
    height: 500px;
    margin: auto;
    .list_item {
      width: 100%;
      height: 160px;
      background: rgba(255, 255, 255, 0.85);
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 0 10px;
      margin-bottom: 10px;
      cursor: pointer;
      .item_img {
        width: 250px;
        height: 140px;
      }
      img {
        object-fit: cover;
        width: 100%;
        height: auto;
      }
      .img_enlarge img {
        animation: enlarge 0.5s;
        -webkit-animation: enlarge 0.5s;
        animation-fill-mode: forwards;
      }
      img.img_smaller {
        animation: smaller 0.5s;
        -webkit-animation: smaller 0.5s;
        animation-fill-mode: forwards;
      }
      // img.main_img:hover {
      //   animation: enlarge 0.5s;
      //   -webkit-animation: enlarge 0.5s;
      // }
      @keyframes enlarge {
        from {
          transform: scale(1);
        }
        to {
          transform: scale(1.2);
        }
      }

      @-webkit-keyframes enlarge /* Safari 与 Chrome */ {
        from {
          transform: scale(1);
        }
        to {
          transform: scale(1.2);
        }
      }
      @keyframes smaller {
        from {
          transform: scale(1.2);
        }
        to {
          transform: scale(1);
        }
      }

      @-webkit-keyframes smaller /* Safari 与 Chrome */ {
        from {
          transform: scale(1.2);
        }
        to {
          transform: scale(1);
        }
      }
      .content {
        width: 745px;
        margin-left: 30px;
      }
      .news_title {
        width: 100%;
        height: 40px;
        font-size: 22px;
        color: #333333;
        margin-bottom: 10px;
        text-align: left;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        line-clamp: 1;
        -webkit-box-orient: vertical;

        // margin-left: 30px;
      }
      .news_title:hover {
        color: #d6ac7d;
      }
      .text {
        width: 100%;
        height: 52px;
        font-size: 18px;
        color: #999999;
        line-height: 25px;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
        text-align: justify;
      }
      .media_arrow {
        width: 34px;
        height: 34px;
        margin-left: 80px;
      }
    }
  }
  .to_media_center {
    display: block;
    margin: 0 auto;
    margin-top: 20px;
    width: 160px;
    height: 54px;
    text-align: center;
    line-height: 54px;
    color: #333333;
    font-size: 20px;
    background: #fff;
    text-decoration: none;
    &:hover {
      color: #fff;
      background: #d6ac7d;
    }
  }
}
.footer {
  height: 100%;
}
</style>
