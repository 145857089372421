<template>
  <div class="footer">
    <div class="concat">联系电话：400-609-0999</div>
    <div class="concat">联系邮箱：ljhnjyjt@163.com</div>
    <div class="concat" style="margin-bottom: 0">
      公司地址：郑州市金水区北三环与中州大道交叉口融创中永中原大观11B30楼
    </div>
    <div class="qr_code">
      <div class="code_content">
        <img src="../assets/imgs/gzh.jpg" alt="" />
        <div class="text">关注公众号</div>
      </div>
      <!-- <div class="code_content" style="margin: 0 130px">
        <img src="../assets/imgs/dy.png" alt="" />
        <div class="text">抖音二维码</div>
      </div>
      -->
      <div class="code_content" style="margin-left: 130px">
        <img src="../assets/imgs/tm.png" alt="" />
        <div class="text">天猫旗舰店</div>
      </div>
      <div class="code_content" style="margin-left: 130px">
        <img src="../assets/imgs/weapp.jpg" alt="" />
        <div class="text">微信小程序</div>
      </div>
    </div>
    <div class="line"></div>
    <div class="copy">
      A​​​​​​​​​​​​​​​​​​Copyright©laojiahenan​​. All Rights Reserved
      <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">
        豫ICP备2022009684号-1</a>
    </div>
    <div class="technology">技术支持：佐尚网络</div>
  </div>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {
      active: 0,
    };
  },
  methods: {
    onChange(index) {
      this.active = index;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.footer {
  background: url("../assets/imgs/footer_bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover;
  background-position: center;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 600px;

  .concat {
    font-size: 20px;
    color: #333333;
    margin-bottom: 23px;
  }

  .qr_code {
    display: flex;
    justify-content: center;
    align-items: center;

    .code_content {
      width: 135px;
      height: 160px;
      background: #ffffff;
      text-align: center;
      padding: 8px;

      .text {
        text-align: center;
        font-size: 14px;
        color: #333333;
        height: 30px;
        line-height: 30px;
      }
    }

    img {
      width: 120px;
      height: 120px;
      object-fit: cover;
    }

    margin-top: 48px;
  }

  .line {
    width: 948px;
    height: 1px;
    background: #ffffff;
    margin: 0 auto;
    margin-top: 48px;
  }

  .copy {
    color: #fff;
    font-size: 14px;
    text-align: center;
    margin: 26px 0;

    a {
      text-decoration: none;
      color: #fff;
      font-size: 14px;
    }
  }

  .technology {
    color: #fff;
    font-size: 14px;
    text-align: center;
  }
}</style>
