<template>
  <div id="menu" class="header">
    <!-- <svg-icon icon-class="refresh" /> -->
    <!-- <img src="../assets/imgs/12-0001.jpg" alt="" />
    <img src="../assets/imgs/b (1).jpg" alt="" />
    <img src="../assets/imgs/b (1).png" alt="" />
    <img src="../assets/imgs/b (10).jpg" alt="" />
    <img src="../assets/imgs/b (12).jpg" alt="" />
    <img src="../assets/imgs/b (11).jpg" alt="" />
    <img src="../assets/imgs/b (15).jpg" alt="" /> -->
    <div class="nav_content">
      <ul class="nav_list">
        <li
          class="nav"
          :class="active == 0 ? 'active' : ''"
          @mouseenter="onMouseenter(0)"
          @mouseleave="onMouseleave(0)"
        >
          <!-- <span>官网首页</span> -->
          <router-link to="/" :class="mouseenter == 0 ? 'mouseenter' : ''">
            <div>
              <span>官网首页</span>
              <div class="line" :class="mouseleave == 0 ? 'mouseleave' : ''"></div>
            </div>
          </router-link>
        </li>
        <li
          class="nav"
          :class="active == 1 ? 'active' : ''"
          @mouseenter="onMouseenter(1)"
          @mouseleave="onMouseleave(1)"
        >
          <!-- <span>集团介绍</span> -->
          <router-link
            to="/groupIntroduction"
            :class="mouseenter == 1 ? 'mouseenter' : ''"
          >
            <div>
              <span>集团介绍</span>
              <div class="line" :class="mouseleave == 1 ? 'mouseleave' : ''"></div>
            </div>
          </router-link>
          <div v-show="showChildList" class="child_list">
            <div class="blank"></div>
            <div class="list_main">
              <div class="item" @click="jump(0)">品牌文化</div>
              <div class="item" @click="jump(1)">战略阶段</div>
              <div class="item" @click="jump(2)">酿造工艺</div>
            </div>
          </div>
        </li>
        <li
          class="nav"
          :class="active == 2 ? 'active' : ''"
          @mouseenter="onMouseenter(2)"
          @mouseleave="onMouseleave(2)"
        >
          <!-- <span>老家礼物</span> -->
          <router-link to="/hometownGift" :class="mouseenter == 2 ? 'mouseenter' : ''">
            <div>
              <span>老家礼物</span>
              <div class="line" :class="mouseleave == 2 ? 'mouseleave' : ''"></div>
            </div>
          </router-link>
          <div v-show="showChildList1" class="child_list">
            <div class="blank"></div>
            <div class="list_main">
              <div
                class="item"
                v-for="(item, index) in seriesList"
                @click="jump1(index)"
                :key="item.id"
              >
                {{ item.label }}
              </div>
              <!-- <div class="item" @click="jump(1)">战略阶段</div>
              <div class="item" @click="jump(2)">酿造工艺</div> -->
            </div>
          </div>
        </li>
      </ul>

      <h1>
        <router-link to="/">
          <img class="logo" src="../assets/imgs/logo.png" alt="老家河南" title="老家河南" />
        </router-link>
      </h1>

      <ul class="nav_list">
        <li
          class="nav"
          :class="active == 3 ? 'active' : ''"
          @mouseenter="onMouseenter(3)"
          @mouseleave="onMouseleave(3)"
        >
          <!-- <span>媒体中心</span> -->
          <router-link to="/mediaCenter" :class="mouseenter == 3 ? 'mouseenter' : ''">
            <!-- <span>媒体中心</span> -->
            <div>
              <span>媒体中心</span>
              <div class="line" :class="mouseleave == 3 ? 'mouseleave' : ''"></div>
            </div>
          </router-link>
          <div v-show="showChildList2" class="child_list">
            <div class="blank"></div>
            <div class="list_main">
              <div class="item" @click="jump2(0)">企业动态</div>
              <div class="item" @click="jump2(1)">新闻报道</div>
            </div>
          </div>
        </li>
        <li
          class="nav"
          :class="active == 4 ? 'active' : ''"
          @mouseenter="onMouseenter(4)"
          @mouseleave="onMouseleave(4)"
        >
          <!-- <span>招商合作</span> -->
          <router-link to="/cooperation" :class="mouseenter == 4 ? 'mouseenter' : ''">
            <!-- <span>招商合作</span> -->
            <div>
              <span>招商合作</span>
              <div class="line" :class="mouseleave == 4 ? 'mouseleave' : ''"></div>
            </div>
          </router-link>
        </li>
        <li
          class="nav"
          :class="active == 5 ? 'active' : ''"
          @mouseenter="onMouseenter(5)"
          @mouseleave="onMouseleave(5)"
        >
          <!-- <span>关于我们</span> -->
          <router-link to="/about" :class="mouseenter == 5 ? 'mouseenter' : ''">
            <!-- <span>关于我们</span> -->
            <div>
              <span>关于我们</span>
              <div class="line" :class="mouseleave == 5 ? 'mouseleave' : ''"></div>
            </div>
          </router-link>
          <div v-show="showChildList3" class="child_list">
            <div class="blank"></div>
            <div class="list_main">
              <div class="item" @click="jump3(0)">集团介绍</div>
              <div class="item" @click="jump3(1)">销售网络</div>
              <div class="item" @click="jump3(2)">联系我们</div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { getSeriesList } from "../api/api";
export default {
  name: "Header",
  props: {
    activeName: String,
  },
  data() {
    return {
      active: 0,
      ready: false,
      mouseenter: -1,
      mouseleave: -1,
      isFirst: true,
      showChildList: false,
      showChildList1: false,
      showChildList2: false,
      showChildList3: false,
      seriesList: [],
    };
  },
  mounted() {
    let _this = this;
    this.ready = false;
    // setTimeout(function () {
    var name = _this.$route.name;
    console.log(name);
    if (name == "Home") {
      _this.active = 0;
    } else if (name == "GroupIntroduction") {
      _this.active = 1;
    } else if (name == "HometownGift") {
      _this.active = 2;
    } else if (name == "MediaCenter" || name == "NewsDetail") {
      _this.active = 3;
    } else if (name == "Cooperation") {
      _this.active = 4;
    } else if (name == "About") {
      _this.active = 5;
    }
    _this.ready = true;
    // }, 100);
    this.getSeriesList();
  },
  // created() {
  //   console.log("created");
  //   var name = this.$route.name;
  //   console.log(name);
  // },
  methods: {
    isIE() {
      //ie?
      if (!!window.ActiveXObject || "ActiveXObject" in window) return true;
      else return false;
    },
    getSeriesList() {
      getSeriesList().then((res) => {
        console.log(res);
        if (res.code == 200) {
          this.seriesList = res.data;
        }
      });
    },
    jump(data) {
      var name = this.$route.name;
      if (name != "GroupIntroduction") {
        this.$router.push({
          path: "/groupIntroduction",
          query: { data: data },
        });
      } else {
        console.log(data);
        this.$emit("link-jump", data);
      }
    },
    jump1(data) {
      var name = this.$route.name;
      if (name != "HometownGift") {
        this.$router.push({
          path: "/hometownGift",
          query: { data: data },
        });
      } else {
        console.log(data);
        // if(this.isIE()){
        //   this.$emit("ie-jump", data);
        // }
        // else{
        //   this.$emit("link-jump1", data);
        // }
        this.$emit("link-jump1", data);
      }
    },
    jump2(data) {
      var name = this.$route.name;
      console.log(name);
      if (name != "MediaCenter") {
        this.$router.push({
          path: "/mediaCenter",
          query: { data: data },
        });
      } else {
        console.log(data);
        this.$emit("link-jump2", data);
      }
    },
    jump3(data) {
      var name = this.$route.name;
      console.log(name);
      if (name != "About") {
        this.$router.push({
          path: "/about",
          query: { data: data },
        });
      } else {
        console.log(data);
        this.$emit("link-jump3", data);
      }
    },
    onMouseenter(index) {
      if (index == this.mouseleave) {
        this.mouseleave = -1;
      }
      this.mouseenter = index;
      if (index == 1) {
        this.showChildList = true;
      } else {
        this.showChildList = false;
      }
      if (index == 2) {
        this.showChildList1 = true;
      } else {
        this.showChildList1 = false;
      }
      if (index == 3) {
        this.showChildList2 = true;
      } else {
        this.showChildList2 = false;
      }
      if (index == 5) {
        this.showChildList3 = true;
      } else {
        this.showChildList3 = false;
      }
      // this.mouseleave = -1;
    },
    onMouseleave(index) {
      // this.isFirst = false;
      console.log(index);
      this.mouseenter = -1;
      this.mouseleave = index;
      if (index == 1) {
        this.showChildList = false;
      }
      if (index == 2) {
        this.showChildList1 = false;
      }
      if (index == 3) {
        this.showChildList2 = false;
      }
      if (index == 5) {
        this.showChildList3 = false;
      }
    },
    onLeave() {
      this.showChildList = false;
    },
    // onChange(index) {
    //   this.active = index;
    //   if (index == 0) {
    //     this.$router.push({
    //       path: "/home",
    //       name: "Home",
    //     });
    //   } else if (index == 1) {
    //     this.$router.push({
    //       path: "/groupIntroduction",
    //       name: "GroupIntroduction",
    //     });
    //   } else if (index == 2) {
    //     this.$router.push({
    //       path: "/hometownGift",
    //       name: "HometownGift",
    //     });
    //   } else if (index == 3) {
    //     this.$router.push({
    //       path: "/mediaCenter",
    //       name: "MediaCenter",
    //     });
    //   } else if (index == 4) {
    //     this.$router.push({
    //       path: "/cooperation",
    //       name: "Cooperation",
    //     });
    //   } else if (index == 5) {
    //     this.$router.push({
    //       path: "/about",
    //       name: "About",
    //     });
    //   }
    //   console.log(this.active);
    // },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.header {
  height: 120px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99;
  background: #fff;
  .nav_content {
    display: flex;
    justify-content: center;
    align-items: center;
    .logo {
      width: 210px;
      height: 74px;
      margin: 0 79px;
    }
    .nav_list {
      -moz-user-select: none; /*火狐*/
      -webkit-user-select: none; /*webkit浏览器*/
      -ms-user-select: none; /*IE10*/
      -khtml-user-select: none; /*早期浏览器*/
      user-select: none;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .nav {
        height: 120px;
        cursor: pointer;
        width: 132px;
        color: #333333;
        font-size: 18px;
        position: relative;
        // font-family: "Arial", "Microsoft YaHei";
        .child_list {
          width: 100%;
          height: auto;
          position: absolute;
          top: 120px;
          left: 0;
          .blank {
            height: 12px;
            background: none;
          }
          .list_main {
            // height: 150px;
            padding: 12px 0;
            background: #fff;
          }
          .item {
            height: 26px;
            font-size: 18px;
            color: #333333;
            line-height: 26px;
            margin-bottom: 24px;
          }
          .item:nth-last-child(1) {
            margin-bottom: 0;
          }
          .item:hover {
            color: #d6ac7d;
          }
        }
        a {
          width: 100%;
          height: 100%;
          display: inline-block;
          text-decoration: none;
          display: flex;
          justify-content: center;
          align-items: center;
          span {
            display: inline-block;
            padding-bottom: 20px;
            margin-top: 20px;
          }
          .line {
            width: 0;
            height: 3px;
            // background: #d6ac7d;
            background: #d6ac7d;
          }
        }
      }
      .nav.active {
        background: #fafafa;
        a {
          span {
            // border-bottom: 3px solid #d6ac7d;
            // margin-top: 23px;
            color: #d6ac7d;
          }
          .line {
            background: #d6ac7d;
            width: 100%;
          }

          // border-bottom: 3px solid #d6ac7d;
          // margin-top: 23px;
        }
      }
      .nav:not(.active) {
        .mouseenter {
          background: #fafafa;
          span {
            // border-bottom: 3px solid #d6ac7d;
            // margin-top: 23px;
            color: #d6ac7d;
          }
          // border-bottom: 3px solid #d6ac7d;
          // margin-top: 23px;
          .line {
            animation: lengthen 0.5s;
            -webkit-animation: lengthen 0.5s; /* Safari and Chrome */
            animation-fill-mode: forwards;
          }
        }
      }
      .nav:not(.active) {
        .line.mouseleave {
          animation: shorten 0.5s;
          -webkit-animation: shorten 0.5s;
          animation-fill-mode: forwards;
        }
      }
      @keyframes lengthen {
        from {
          width: 0;
        }
        to {
          width: 100%;
        }
      }

      @-webkit-keyframes lengthen /* Safari 与 Chrome */ {
        from {
          width: 0;
        }
        to {
          width: 100%;
        }
      }

      @keyframes shorten {
        from {
          width: 100%;
        }
        to {
          width: 0;
        }
      }

      @-webkit-keyframes shorten /* Safari 与 Chrome */ {
        from {
          width: 100%;
        }
        to {
          width: 0;
        }
      }
    }
  }
}
</style>
