import request from "@/utils/request"
// banner列表
export function getBannerList(data) {
    return request({
        url: '/home/bannerList',
        method: 'get',
        data
    })
}
// 首页-产品中心
export function getProductList(data) {
    return request({
        url: '/home/products',
        method: 'get',
        data
    })
}
// 媒体中心 
export function getNewsList(data) {
    return request({
        url: '/home/newsList',
        method: 'get',
        params: data
    })
}
// 老家礼物-根据系列分组
export function getGroupProducts(data) {
    return request({
        url: '/home/groupProducts',
        method: 'get',
        params: data
    })
}
// 招商合作
export function addIntention(data) {
    return request({
        url: '/home/addIntention',
        method: 'post',
        data
    })
}
// 
export function getSaleList(data) {
    return request({
        url: '/home/channelList',
        method: 'get',
        params: data
    })
}
// 
export function getNewDetail(data) {
    return request({
        url: '/home/newsEdit',
        method: 'get',
        params: data
    })
}
// 
export function getSeriesList(data) {
    return request({
        url: '/home/getSeriesList',
        method: 'get',
        params: data
    })
}
// 
export function getLandingPage(data) {
    return request({
        url: '/home/getLandingPage',
        method: 'get',
        params: data
    })
}