<template>
  <div id="app">
    <!-- <img alt="Vue logo" src="./assets/logo.png"> -->
    <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->
    <!-- <router-view></router-view> -->
    <keep-alive v-if="$route.name!='Home'">
      <router-view v-if="$route.meta.keepAlive">
        <!-- 这里是会被缓存的视图组件，比如 Home！ -->
      </router-view>
    </keep-alive>

    <router-view v-if="!$route.meta.keepAlive">
      <!-- 这里是不被缓存的视图组件，比如 Edit！ -->
    </router-view>
    <!-- <Footer></Footer> -->
  </div>
</template>

<script>
import Header from "./components/header";

export default {
  name: "App",
  components: {
    // swiper,
    // swiperSlide,
    Header,
    // Footer,
  },
};
</script>

<style lang="scss">
// html{
//   height: calc(100vh - 120px) !important;
//   margin-top: 120px !important;
// }
html,
body,
div,
h1,
h2,
h3,
h4,
h5,
h6,
p,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
input,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
textarea,
article,
aside,
audio,
canvas,
figure,
footer,
header,
mark,
menu,
nav,
section,
time,
video {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%;
  // font-weight: normal;
}
article,
aside,
dialog,
figure,
footer,
header,
hgroup,
nav,
section,
blockquote {
  display: block;
}
ul,
ol {
  list-style: none;
}
img {
  border: 0 none;
  vertical-align: top;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
strong,
em,
i {
  font-style: normal;
  // font-weight: normal;
}
ins {
  text-decoration: underline;
}
del {
  text-decoration: line-through;
}
mark {
  background: none;
}
input::-ms-clear {
  display: none !important;
}
body {
  font: 12px/1.5 \5fae\8f6f\96c5\9ed1, \5b8b\4f53, "Hiragino Sans GB", STHeiti,
    "WenQuanYi Micro Hei", "Droid Sans Fallback", SimSun, sans-serif;
  background: #fff;
}
a {
  text-decoration: none;
  color: #333;
}
a:hover {
  text-decoration: underline;
}
body {
  margin: 0;
  padding: 0;
}
// div {
//   // box-sizing: border-box;
// }
#app {
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /* margin-top: 160px; */
  min-width: 1200px;
}

.img_content_pub {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  img {
    width: auto;
    height: 100%;
    // width: 100%;
    // height: 100%;
    object-fit: cover;
  }
}
.banner {
  width: 100%;
  // height: 800px;
  margin-top: 120px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.swiper-container-horizontal > .swiper-pagination-bullets,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  display: flex;
  justify-content: center;
  align-items: center;
}
.swiper-pagination-bullet-active {
  width: 12px !important;
  height: 12px !important;
}
</style>
