var historyList = [[{
		img: require('../assets/historyImg/2009.png'),
		year: '2009年',
		title: '记忆中原,老家河南”宣传片',
		desc: '5月在中央电视台播出，全片1分35秒，不仅对河南的旅游资源起到了很好的宣传推介作用，而且传达出中原河南作为中华文化之源厚重的亲情气息。"老家”的宣传语朴实、亲切，引起了不少河南籍游子的共鸣，具有河南特色的景观也吸引了全国各地的游客前来寻访孕育中原文化的“老家"。'

	},
	{
		img: require('../assets/historyImg/2012.png'),
		year: '2012年',
		title: '老家河南品牌建立',
		desc: '7月公司召开老家河南品牌发展规划工作会议，“老家” 品牌是在“老家河南”的基础上以华夏文化之根作为品牌规划核心，将河南的历史沉淀、乡情风貌、旅游资源、发展态势在老家品牌上得到具体的体现。它通过挖掘，梳理华夏文明传统文化，通过传承创新的现化产业化发展，使华夏文明、中原文化和“老家”情感通过人文景观、传统民俗、中原民居、地方特产等产业成为可以感受、品尝、触摸、体验的立体存在，将河南打造成全球华人的“精神故乡”。9月提出关于申请注册“老家”系列品牌的请示。9月河南省人民政府办公厅同意关于河南省中视新科文化产业有限公司负责申请、持有、管理、经营“老家河南”系列商标，享有商标专有使用权。'
	}],
	[{
		img: require('../assets/historyImg/2013.png'),
		year: '2013年',
		title: '参加2013年度“中国十佳旅游口号”评比',
		desc: '7月在2013“中国十佳旅游口号”评比中，“老家河南” 名列“十佳”,同时入榜的“好客山东”2011年品牌估值已达到115亿元，“老家河南” 品牌价值不言而喻。'
	},
	{
		img: require('../assets/historyImg/2014.png'),
		year: '2014年',
		title: '老家品牌商标注册',
		desc: '中视新科完成“老家”品牌上百项商标专利注册。'
	},
	{
		img: require('../assets/historyImg/2016.png'),
		year: '2016年',
		title: '郑州东站“老家河南” 形象主题店铺',
		desc: '9月开设郑州东站“老家河南” 形象主题店铺，取得了良好的市场反应。使“老家河南”这一文化品牌概念更加立体，更为“老家河南”品牌在全国的广泛推广积攒人气。另一方面打造老家系列媒体矩阵，着力形成文化品牌的统一。'
	},

	{
		img: require('../assets/historyImg/2017.jpg'),
		year: '2017年',
		title: '《豫见中国，老家河南》宣传片',
		desc: "2月发布的《豫见中国，老家河南》是由河南省旅游局主导拍摄制作的新版河南旅游宣传片。该片从2016年开始筹备，历时年。导演为赵天宇，代表作:《微微一笑很倾城》。该宣传片内容分为四个部分:遇见中国河、遇见中国字、遇见中国艺术、遇见中国功夫。四个部分深刻挖掘了中华文化的源头和最核心部分，充分体现了河南的文化软实力。"
	},
	{
		img: require('../assets/historyImg/2017_1.png'),
		year: '2017-2019年',
		title: '老家河南文化周',
		desc: '为配合黄帝故里拜祖大典，于2017年、2018年和2019年分别在拜祖大典期间举办“老家河南”文化周活动。由省委宣传部、省文联和省文化和旅游厅等单位主导，内容包括优秀舞台艺术展演、优秀影视作品展映(播)、优秀书法美术作品展览、精品图书展、非遗项目展示等五大类13项活动，集中展现河南优秀文化发展成果。'
	},
	{
		img: require('../assets/historyImg/2019.jpg'),
		year: '2019年',
		title: '《老家河南·城市面孔”》',
		desc: '12月“老家河南·城市面孔”首届城市标签人物潇光摄影肖像展活动由郑州报业集团、中原凤凰文化产业集团主办，彩虹盒子艺术园区、能量橙子文创100计划共同承办，是属于中原人像飞速发展的又一个里程碑事件。'
	}],
	// =========================
	[{
		img: require('../assets/historyImg/2020_1.jpeg'),
		year: '2020年',
		title: '参加郑州国际糖酒会',
		desc: '8月省级文化名片“老家河南”酒类产品重磅亮相郑州糖酒会，"老家河南"是河南省重要旅游文化名片，作为白酒商标亮相全国平台则是行业第一次。传播河南文化，传播河南酒文化。'
	},
	{
		img: require('../assets/historyImg/2020_2.jpg'),
		year: '2020年',
		title: '《河南形象全球化传播暨这就是河南》',
		desc: '11月河南形象全球化传播暨这就是河南，由河南省委宣传部、省政府新闻办、河南省委网信办、河南省文化和旅游厅、河南省国际文化交流中心指导，豫记主办的河南形象全球化传播暨这就是河南，老家河南作为指定用酒全程支持。'
	},
	{
		img: require('../assets/historyImg/2020_3.jpg'),
		year: '2020年',
		title: '《豫韵丹青，老家河南》',
		desc: '为促进中原文化交流发展，郑州文化馆在12月29日下午于馆内举办“豫韵丹青，老家河南”——书画交流活动，共有河南籍画家、书法家近40多名嘉宾出席活动。此次活动特别邀请中国书法家协会会员、郑州市书画研究会副会长唐毅老师到现场与众多书法爱好者进行现场交流。在交流会上，唐老师对部分作品进行了详细的讲解与点评，现场泼墨给大家做示范，并亲自为“老家河南”题字。'
	},

	// 2021===================
	{
		img: require('../assets/historyImg/2021_1.jpg'),
		year: '2021年',
		title: '老家河南®全球战略合作暨酒业新品上市发布盛典',
		desc: '4月华夏之源，醉美中原——老家河南®全球战略合作暨酒业新品上市发布盛典。首次以著名IP“老家河南”作为独立产品品牌亮相的酱酒新品“豫鉴”系列发布会，引起了业内外高度关注。'
	},
	{
		img: require('../assets/historyImg/2021_199.jpg'),
		year: '2021年',
		title: '老家河南酒业集团有限公司正式注册成立',
		desc: '10月老家河南酒业集团有限公司于2021年10月15日正式注册成立，由河南省文旅投资集团旗下的河南中视新科文化产业发展有限公司联合河南日报报业集团旗下河南大河网络传媒集团有限公司共同合资成立，民营企业参股，以市场为导向，做国资控股、民营主导的市场型合资企业。老家河南系列商标转由合资公司独家运营使用。'
	},
	{
		img: require('../assets/historyImg/2021_2.jpg'),
		year: '2021年',
		title: '“回老家河南，看文博中原” ',
		desc: '11月第八届中原文化产业博览交易会开幕，河南省文投集团重点项目“老家河南酒”重磅亮相，展示河南文化力量。'
	},
	{
		img: require('../assets/historyImg/2021_3.jpg'),
		year: '2021年',
		title: '河南文旅集团领导至老家河南酒业集团调研',
		desc: '11月河南文旅集团筹备组组长吴孟铎、副组长张立新等一行至老家河南酒业集团调研'
	},
	{
		img: require('../assets/historyImg/2021_4.jpg'),
		year: '2021年',
		title: '《老家河南·山水济源》',
		desc: '12月济源文旅集团X老家河南酒业集团达成战略合作。山水济源定制项目合作签约，助力河南文化传播，讲述河南故事，递交“河南名片”。'
	},

	// 2022===========================
	{
		img: require('../assets/historyImg/2022_1.jpg'),
		year: '2022年',
		title: '携手 “家居美学”行业',
		desc: '3月“老家河南”携手“家居美学”行业，开启商业版图新篇章，助力老家河南酒多维度发展。'
	},
	{
		img: require('../assets/historyImg/2022_2.jpg'),
		year: '2022年',
		title: '助力超继·金象高尔夫俱乐部首届邀请赛',
		desc: '3月“瑞虎迎春 金象更新”， 2022超继·金象高尔夫俱乐部首届邀请赛圆满落下帷幕，老家河南酒全程赞助支持，为参赛者添写了难忘的“时光和精彩”的记忆。老家河南酒为每一场精彩赛事加油鼓劲。'
	},
	{
		img: require('../assets/historyImg/2022_3.jpg'),
		year: '2022年',
		title: '“老家河南，问道中原”',
		desc: '第一站：老家河南家谱馆。3月老家河南酒业集团负责人专程到“老家河南家谱馆”参观考察。河南省家谱研究会会长魏怀习陪同考察，并详细介绍了家谱馆各个展厅的布展特色。老家河南酒业集团为进一步传承弘扬根亲文化和中华传统文化而努力，为宣传老家河南文化而努力。第二站：御玺中州国际饭店。3月老家河南酒业集团负责人与营销总经理刘重天深入交流，倾听市场声音。'
	},]
]

var fiveTab = [

]




export {
	historyList

}
